@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.detail_detailsContainer__3d0vP {
    font-family: 'Poppins', sans-serif;
    margin-top: 40px;
}

.detail_detailsContainer__3d0vP ul {
    margin: 0;
    padding: 0;
    text-decoration: none;
    width: 80vw;
    margin: 0 auto;
}

.detail_detailsContainer__3d0vP ul li {
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
    width: 80vw;
}

.detail_detailsContainer__3d0vP div {
    width: 60%;
}

.detail_image__26spc {
    width: 100px;
    height: 50px;
    display: inline-block;
    vertical-align: middle;
    box-shadow: 1px 1px 3px 2px #efefef;
}

.detail_image__26spc + div {
    display: inline-block;
    vertical-align: middle;
    margin: 0px;
    margin-left: 10px;
}

.detail_title__1wxM8 {
    margin: 0;
    width: 60vw;
    font-size: 15px;
    overflow-wrap: break-word;
}

.detail_link__QJZIZ {
    display: block;
    text-decoration: none;
    font-size: 12px;
    letter-spacing: 0.5px;
    text-align: left;
    color: #1a1a1a;
    font-weight: bold;
    overflow-wrap: break-word;
}

.detail_deleteButton__oGk9u {
    border: 1px solid transparent;
    padding: 8px 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    cursor: pointer;
    margin-left: 20px;
    position: absolute;
    right: 0;
    background: #2962FF;
    color: #FFFFFF;
    letter-spacing: 2px;
}

@media (max-width: 900px) {
    .detail_title__1wxM8 {
        width: 50vw;
        font-size: 13px;
        line-height: 1.1rem;
    }
    .detail_link__QJZIZ {
        font-size: 10px;
        letter-spacing: normal;
    }
    .detail_deleteButton__oGk9u {
        top: 50%;
        transform: translateY(-50%);
    }
}

@media (max-width: 600px) {
    .detail_deleteButton__oGk9u {
        position: static;
        margin: 0;
        width: 100%;
        transform: none;
        margin-top: 10px;
    }
    .detail_detailsContainer__3d0vP div {
        width: calc(100% - 110px);
    }
    .detail_detailsContainer__3d0vP ul li {
        margin-bottom: 40px;
    }
    .detail_title__1wxM8 {
        width: 100%;
    }
}

html, body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.saveUrl_container__1DWGZ {
    width: 100%;
}

.saveUrl_header__1IVjz {
    position: fixed;
    width: 100vw;
    top: 0px;
    box-shadow: 1px 0px 2px 2px #efefef;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    background: #2962FF;
    color: #FFFFFF;
}

.saveUrl_header__1IVjz h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 2rem;
    font-weight: 200;
    letter-spacing: -0.5px;
    padding-top: 10px;
}

.saveUrl_header__1IVjz .saveUrl_icon__-KqqE {
    font-weight: bold;
    font-size: 3.5rem;
    margin-right: 0.2rem;
}

.saveUrl_urlContainer__27fmS {
    text-align: center;
    margin-top: 8rem;
}

.saveUrl_input__3d-Fe {
    font-family: 'Poppins', sans-serif; 
    padding: 8px;
    width: 40vw;
    border: 2px solid #2962FF;
    color: #2962FF;
    outline: #2962FF;
}

.saveUrl_input__3d-Fe::-webkit-input-placeholder {
    color: #2962FF;
}

.saveUrl_input__3d-Fe:-ms-input-placeholder {
    color: #2962FF;
}

.saveUrl_input__3d-Fe::placeholder {
    color: #2962FF;
}

.saveUrl_submitButton__Gvu8d {
    border: 1px solid transparent;
    padding: 9px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    cursor: pointer;
    background: #2962FF;
    color: #FFFFFF;
}

.saveUrl_noLinks__1D-Mz {
    font-family: 'Poppins', sans-serif;
    font-size: 3rem;
    text-align: center;
}
